import React from 'react';

const About = () => {
  return (
    <div>
    <h2>About</h2>
    <p>Welcome to <strong>Pillarwheel.io</strong>—your gateway to the future of gaming and blockchain technology. As the digital hub of Pillarwheel Studios, this platform connects you to our cutting-edge web3 applications, offering a new level of interaction and ownership within the gaming universe.</p>
    
    <h3>What is Pillarwheel.io?</h3>
    <p>Pillarwheel.io is more than just a website; it's an immersive experience where gaming and blockchain technology converge. Dive into our play-to-earn games, where every move you make has the potential to earn real-world value. This platform supports and empowers players, offering an ecosystem where you can mint NFTs, trade digital assets, and fully engage with the worlds we create.</p>
    
    <h3>Web3 Integration</h3>
    <p>At the heart of Pillarwheel.io is our commitment to web3 integration, bringing decentralization, security, and true ownership to our community. By leveraging blockchain technology, we're able to provide a seamless and transparent experience for players. Whether you're minting NFTs, trading assets, or interacting with our games, every action you take is secured and recorded on the blockchain, giving you full control over your digital belongings.</p>
    
    <h3>Mint NFTs and Own Your Experience</h3>
    <p>One of the most exciting aspects of Pillarwheel.io is the ability to mint NFTs directly on the platform. We believe in giving players real ownership of their in-game assets. Our NFT minting process allows you to create unique digital items that you can own, trade, or even use across different games in our ecosystem. This isn’t just about playing; it’s about building and owning a part of the game worlds you love.</p>
    
    <h3>Play-to-Earn Games</h3>
    <p>Pillarwheel.io is home to our growing portfolio of play-to-earn (P2E) games. In these innovative titles, your time and skill are rewarded with digital assets that have real-world value. As you explore, battle, and build within these games, you'll be accumulating assets that can be traded, sold, or held as investments. It's gaming like you've never experienced before—where every victory has tangible value.</p>
    
    <h3>Join the Pillarwheel Community</h3>
    <p>We’re building a vibrant community of gamers, creators, and blockchain enthusiasts. Pillarwheel.io is not just a platform; it's a gathering place for those who want to be part of the next generation of gaming. By joining our community, you’ll get access to exclusive content, early access to new games, and the chance to shape the future of Pillarwheel Studios through feedback and engagement.</p>
    
    <p>Experience the future with Pillarwheel.io. Step into a world where gaming, blockchain, and ownership merge. At Pillarwheel.io, the games are more than just entertainment—they’re a new way to play, earn, and own. Explore our platform today and become part of a gaming revolution.</p>
    </div>
  );
};

export default About;
