import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Web3 from "web3";
import { ImmutableXClient } from "@imtbl/imx-sdk";
import './App.css'; // Import the CSS file

// Import pages
import About from "./pages/About";
import Games from "./pages/Games";
import DAOs from "./pages/DAOs";
import Merchandise from "./pages/Merchandise";

// Fetch the API key from environment variables
const imxApiKey = process.env.REACT_APP_IMX_API_KEY;

const App = () => {
  const [account, setAccount] = useState(null);
  const [status, setStatus] = useState("Not connected");
  const [tier, setTier] = useState("Tier 1");  // Default to Tier 1

  // Connect wallet using MetaMask
  const connectWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        setStatus(`Connected: ${accounts[0]}`);
      } catch (error) {
        setStatus("Failed to connect wallet");
        console.error(error);
      }
    } else {
      setStatus("MetaMask is not installed");
    }
  };

  // Mint NFT using Immutable X
  const mintNFT = async () => {
    if (!account) {
      setStatus("Please connect wallet first");
      return;
    }

    // Define metadata based on the selected tier
    let tokenMetadata;
    if (tier === "Tier 1") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 1",
        description: "Access exclusive Tier 1 content",
        image: "https://pillarwheel.mypinata.cloud/ipfs/QmdCwdsCNhuHbppRhGWvMkg2nVDgwrLZVSiQz4MRprHxzf",  // Tier 1 image
      };
    } else if (tier === "Tier 2") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 2",
        description: "Access exclusive Tier 2 content",
        image: "https://pillarwheel.mypinata.cloud/ipfs/QmdCwdsCNhuHbppRhGWvMkg2nVDgwrLZVSiQz4MRprHxzf",  // Tier 2 image (same or different)
      };
    } else if (tier === "Tier 3") {
      tokenMetadata = {
        name: "Pillarwheel NFT - Tier 3",
        description: "Access exclusive Tier 3 content",
        image: "https://pillarwheel.mypinata.cloud/ipfs/QmdCwdsCNhuHbppRhGWvMkg2nVDgwrLZVSiQz4MRprHxzf",  // Tier 3 image (same or different)
      };
    }

    try {
      const client = new ImmutableXClient({ publicApiUrl: "https://api.sandbox.x.immutable.com/v1" });
      await client.mint({
        user: account,
        token_id: `PillarwheelNFT-${tier}`,  // Unique token ID for tier
        token_address: "YOUR_SMART_CONTRACT_ADDRESS",  // Replace with your contract address
        metadata: tokenMetadata,
      });
      setStatus("NFT minted successfully");
    } catch (error) {
      setStatus("Failed to mint NFT");
      console.error(error);
    }
  };

  return (
    <Router>
      <div>
        <nav className="navbar">
          <ul>
            <li><Link to="/">Shop</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/games">Games</Link></li>
            <li><Link to="/daos">DAOs</Link></li>
            {/* External link to open in a new window */}
            <li><a href="https://medium.com/@pillarwheel" target="_blank" rel="noopener noreferrer">News</a></li>
            <li><Link to="/merchandise">Merchandise</Link></li>
          </ul>
        </nav>
        <div className="content">
          <Routes>
            <Route 
              path="/" 
              element={
                <Shop 
                  connectWallet={connectWallet} 
                  account={account} 
                  status={status} 
                  tier={tier} 
                  setTier={setTier} 
                  mintNFT={mintNFT} 
                />
              } 
            />
            <Route path="/about" element={<About />} />
            <Route path="/games" element={<Games />} />
            <Route path="/daos" element={<DAOs />} />
            <Route path="/merchandise" element={<Merchandise />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

// Define the Shop page component
const Shop = ({ connectWallet, account, status, tier, setTier, mintNFT }) => (
  <div className="app-container">
    <h1>Pillarwheel NFT Minting</h1>
    <button onClick={connectWallet}>Connect Wallet</button>
    
    {/* Dropdown to select tier */}
    <div className="select-container">
      <label htmlFor="tier-select">Select Tier: </label>
      <select id="tier-select" value={tier} onChange={(e) => setTier(e.target.value)}>
        <option value="Tier 1">Tier 1</option>
        <option value="Tier 2">Tier 2</option>
        <option value="Tier 3">Tier 3</option>
      </select>
    </div>
    
    {/* <button onClick={mintNFT} disabled={!account}>Mint NFT</button> */}
    <p className="status-message">{status}</p>
  </div>
);

export default App;
